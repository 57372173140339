import React from 'react';
import './SubMenuItem.css';

function SubMenuItem(props) {
  return (
    <li 
      className={`sub-menu-item ${props.active ? 'active' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        props.item.onClick();
      }
    }
    >
      {props.item.itemName.toUpperCase()}
    </li>
  );
}

export default SubMenuItem;
