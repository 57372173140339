import React from 'react';
import './Hamburger.css';

const Hamburger = (props) => (
  <div
    className="hamburger-wrapper"
    onClick={props.onClick}
  >
    <label className="hamburger">
      <span/>
    </label>
  </div>
);

export default Hamburger;
