import React from 'react';
import Gallery from '../components/Gallery/Gallery';
import Page from '../components/Page/Page';

function GalleryView() {
  return (
    <Page>
      <Gallery title="Lamina Series" />
    </Page>
  );
}

export default GalleryView;
