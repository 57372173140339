import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

import AboutView from './views/AboutView';
import Base from './components/Base';
import GalleryView from './views/GalleryView';
import HomeView from './views/HomeView';
import ContactView from './views/ContactView';
import BioView from './views/BioView';
import CVView from './views/CVView';

const Routes = () => (
  <React.Fragment>
    <Switch component={Base}>
    <Route exact path="/About" component={AboutView} />
    <Route exact path="/Gallery" component={GalleryView} />
    <Route exact path="/Contact" component={ContactView} />
    <Route exact path="/Biography" component={BioView} />
    <Route exact path="/CV" component={CVView} />
    <Route path="*" component={HomeView} />
    </Switch>
  </React.Fragment>
);

export default Routes;
