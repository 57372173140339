import React from 'react';
import TextBlock from '../components/TextBlock/TextBlock';
import Banner from '../components/Banner/Banner';
import Page from '../components/Page/Page';
import Banner3 from '../images/banner3.jpg';

function BioView() {
  return (
    <Page>
      <Banner 
        img={Banner3}
        title="Biography" />
      <TextBlock>
      Monica O’ Meara was raised in the Midlands in Ireland. Moving to Dublin she embraced the artistic and cultural opportunities city life presented, regularly undertaking art and craft hobby courses throughout her working life. Having returned to live in the Midlands the opportunity arose to study art and design on a full-time basis, completing Level 5 &amp; Level 6 in Abbeyleix College of Further Education in 2015 &amp; 2016. She continued her studies in Limerick School of Art and Design where she completed the Honours BA in Ceramic Design in 2019.
      <br/><br/>
      Nature and landscape have always been her primary source of inspiration and her interests in local and international travel and hillwalking have provided a vast wealth of source material with which to work. Having worked across various media, her practice now concentrates on ceramics. This close affinity with geology and the landscape is often translated through abstracted colours witnessed on her walks and travels. With a keen interest in the scientific elements of her practice, O’ Meara works in a conceptual and experimental manner, allowing both materials and firing their own voice in the process. Glazes become part of the fabric of the material she creates rather than surface decoration. The result of this interplay is the creation of unrepeatable truly unique abstract sculptural pieces.
      <br/><br/>
      O’ Meara’s work is held in both public and private collections including Limerick School of Art and Design, Limerick Institute of Technology and BD Research Centre Ireland.
    </TextBlock>
    </Page>
  );
}

export default BioView;
