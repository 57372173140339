import React from 'react';
import Page from '../components/Page/Page';
import Banner4 from '../images/banner4.jpg';
import Banner from '../components/Banner/Banner';
import CV from '../components/CV/CV';

function CVView() {
  return (
    <Page>
      <Banner
        img={Banner4}
        title="Curriculum Vitae"
      />
      <CV />
    </Page>
  );
}

export default CVView;
