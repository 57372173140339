import React from 'react';
import TextBlock from '../components/TextBlock/TextBlock';
import Banner from '../components/Banner/Banner';
import Page from '../components/Page/Page';
import Banner1 from '../images/banner1.jpg';

function AboutView() {
  return (
    <Page>
      <Banner 
        img={Banner1}
        author="John Ruskin"
        title="Statement" 
        text="'An architect should live as little in cities as a painter. Send him to our hills,
and let him study there what nature understands by a buttress, and what by a dome.'"/>
      <TextBlock>
        I feel deeply connected to untamed landscapes - the physical marks, exposed rocks, cracks and folds, contrasting colours and textures that evidence their creation.
        <br/><br/>
        My work explores geology through the lens of time, or time through the lens of geology. It investigates the notion of capturing and accelerating time through ceramic processes. Ceramic construction methods and materials echo the brute natural forces intrinsic to the formation of geological strata and their subsequent breakdown and exposure through erosion – crushing, crashing, scorching, rupturing and shattering.
        <br/><br/>
        I work intuitively allowing the materials to have their own expressive voice. The kiln simulates earth’s furnace, interacting intense heat with materials.  My work embraces this inherent unpredictability, becoming a vignette of momentous geological events, celebrating nature as architect.
      </TextBlock>
    </Page>
  );
}

export default AboutView;
