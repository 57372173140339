import React, { Component } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import './Menu.css';
import { withRouter } from "react-router-dom";
import Hamburger from '../Hamburger/Hamburger';
import CloseHamburger from '../CloseHamburger/CloseHamburger';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  toggleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open
    }));
  }

  navigate = (path) => {
    this.props.history.push(path);
  }

  render() {
    const location = this.props.location.pathname;

    return (
      <div className="menu">
        <Hamburger onClick={this.toggleOpen} />
        <div
          className="menu-title"
          onClick={() => this.navigate('')}
        >
          MONICA O' MEARA
        </div>
        <div 
          className={`menu-items${this.state.open ? ' menu-items-show menu-transition' : ''}`}
        >
          <CloseHamburger onClick={this.toggleOpen} />    
          <MenuItem 
            itemName="Home"
            onClick={() => this.navigate('')}
            active={location === '/'}
          />
          <MenuItem 
            itemName="About"
            className="menu-item"
            onClick={() => this.navigate('/About')}
            active={location === '/About'}
            subNavs={[
              {
                itemName: 'Statement',
                active: location === '/About',
                onClick: () => {this.navigate('/About')},
              },
              {
                itemName: 'Biography',
                active: location === '/Biography',
                onClick: () => {this.navigate('/Biography')},
              },
              {
                itemName: 'CV',
                active: location === '/CV',
                onClick: () => {this.navigate('/CV')},
              }
            ]}
            />
          <MenuItem
            itemName="Gallery"
            onClick={() => this.navigate('/Gallery')}
            active={location === '/Gallery'}
            />
          <MenuItem
            itemName="Contact"
            onClick={() => this.navigate('/Contact')}
            active={location === '/Contact'}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
