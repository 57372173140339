import React from 'react';
import './Gallery.css';
import Tile from '../Tile/Tile';
import Gallery1 from '../../images/Gallery1.jpg';
import Gallery2 from '../../images/Gallery2.jpg';
import Gallery3 from '../../images/Gallery3.jpg';
import Gallery4 from '../../images/Gallery4.jpg';
import Gallery5 from '../../images/Gallery5.jpg';
import Gallery6 from '../../images/Gallery6.jpg';
import Gallery7 from '../../images/Gallery7.jpg';
import Gallery8 from '../../images/Gallery8.jpg';
import Gallery9 from '../../images/Gallery9.jpg';
import Gallery10 from '../../images/Gallery10.jpg';
import Gallery11 from '../../images/Gallery11.jpg';
import Gallery12 from '../../images/Gallery12.jpg';


const data = [{
  id: 1,
  title: 'Uplift 1',
  dimensions: '4.5(H) x 35.5(W) x 11(D) cm',
  image: Gallery1
},
{
  id: 2,
  title: 'Uplift 1',
  dimensions: 'Detail',
  image: Gallery2
},
{
  id: 3,
  title: 'Cycle 3',
  dimensions: '19(H) x 20.5(W) x 8(D) cm ',
  image: Gallery3
},
{
  id: 4,
  title: 'Cycle 4',
  dimensions: '19(H) x 19(W) x 7(D) cm',
  image: Gallery4
},
{
  id: 5,
  title: 'Mantle 1',
  dimensions: 'Detail',
  image: Gallery5
},
{
  id: 6,
  title: 'Mantle 1 / Mantle 2',
  dimensions: '5.5(H) x 12(W) x 12(D) cm each',
  image: Gallery6
},
{
  id: 7,
  title: 'Cycle 4',
  dimensions: 'Detail',
  image: Gallery7
},
{
  id: 8,
  title: 'Sinter 2',
  dimensions: '9(H) x 23(W) x 27(D) cm',
  image: Gallery8
},
{
  id: 9,
  title: 'Mantle 3 / Mantle 4',
  dimensions: '3.5(H) x 7(W) x 7(D) cm each',
  image: Gallery9
},
{
  id: 10,
  title: 'Sinter 1',
  dimensions: '6.5(H) x 10(W) x 10.5(D) cm',
  image: Gallery10
},
{
  id: 11,
  title: 'Sinter 1',
  dimensions: 'Detail',
  image: Gallery11
},
{
  id: 12,
  title: 'Cycle 2',
  dimensions: '19(H) x 19(W) x 7(D) cm',
  image: Gallery12
}];

const Gallery = (props) => (
  <React.Fragment>
    <br/>
    <h2 className="gallery-title">
      { props.title.toUpperCase() }
    </h2>
    <div className="tiles">
      {data.map((data) => (
        <Tile
          image={data.image}
          title={data.title}
          dimensions={data.dimensions}
          key={data.id}
        />
      ))}
    </div>
  </React.Fragment>
);

export default Gallery;