import React from 'react';
import Page from '../components/Page/Page';
import Banner from '../components/Banner/Banner';
import Banner2 from '../images/banner2.jpg';
import SocialBar from '../components/SocialBar/SocialBar';

function ContactView() {
  return (
    <Page>
      <br/>
      <br/>
      <br/>
      <br/>
      <Banner 
        img={Banner2}
        title="Contact"
        email="monicaomearaart@gmail.com"
      />
      <SocialBar/>
    </Page>
  );
}

export default ContactView;
