import React from 'react';
import './TextBlock.css';

function TextBlock(props) {
  return (
    <div className="text-block">
      {props.children}
    </div>
  );
}

export default TextBlock;
