import React from 'react';
import './Slideshow.css';
import Slideshow1 from '../../images/Slideshow1.jpg';
import Slideshow2 from '../../images/Slideshow2.jpg';
import Slideshow3 from '../../images/Slideshow3.jpg';

const Slideshow = (props) => (
  <ul class="slideshow">
    <li><span style={{backgroundImage: `url("${Slideshow1}")`}}>Image 01</span></li>
    <li><span style={{backgroundImage: `url("${Slideshow2}")`}}>Image 02</span></li>
    <li><span style={{backgroundImage: `url("${Slideshow3}")`}}>Image 03</span></li>
  </ul>
);

export default Slideshow;