import React from 'react';
import './SocialBar.css';
import Insta from '../../images/instagram.svg';
import Email from '../../images/email.svg';

const SocialBar = () => (
  <div className="social-wrapper">
    <div className="social-bar">
      <a href="https://www.instagram.com/monicaomearaart">
        <img src={Insta} alt="Instagram"/>
      </a>
      <a href="mailto:monicaomearaart@gmail.com">
        <img src={Email} alt="eMail"/>
      </a>
    </div>
  </div>
);

export default SocialBar;
