import React from 'react';
import Page from '../components/Page/Page';
import Slideshow from '../components/Slideshow/Slideshow';

function HomeView() {
  return (
    <Page>
      <Slideshow/>
    </Page>
  );
}

export default HomeView;
