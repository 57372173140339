import React from 'react';
import './CV.css';
import TextBlock from '../TextBlock/TextBlock';

const CV = () => (
  <TextBlock>
  <p className="cv">
    CURRICULUM VITAE
    <br/><br/>
    Monica O’ Meara 
    <br/><br/>
    Contact:<br/>
    <a href="/">
      ​www.momeara.art
    </a>
    <br/>
    <a href="mailto:monicaomearaart@gmail.com">
      monicaomearaart@gmail.com
    </a>
    <br/>
    <br/>
    <br/><br/>
    EDUCATION<br/>
    <br/><br/>
    <tr>
      <td className="cv-year">
        2016-2019
      </td>
      <td>
        Ceramics – BA (Honours) in Design, Limerick School of Art and Design, Clare Street, Limerick.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015-2016
      </td>
      <td>
        Advanced Certificate in Art - QQI Level 6, Abbeyleix FEC, Mountrath Road., Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2014-2015
      </td>
      <td>
        Art Craft and Design - FETAC Level 5. Abbeyleix FEC, Mountrath Road, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <br/><br/>
    RESIDENCIES WORKSHOPS and OTHER EDUCATION
    <br/><br/>
    <tr>
      <td className="cv-year">
        2018&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        Paul Wearing Ceramics (Work Placement), Fireworks Clay Studios, Cardiff, Wales.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2017
      </td>
      <td>
        Sara Roberts Ceramics (Work Placement), Nohoval, Co Cork.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2016
      </td>
      <td>
        Plein Air Painting Workshop – Jock Nichol, Glenbarrow, Co. Laois
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2016
      </td>
      <td>
        Group Sculpture Project, Dove House Project, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015
      </td>
      <td>
        Plein Air Painting Workshop – Jock Nichol, Stradbally, Co. Laois
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015
      </td>
      <td>
        Group Public Sculpture Project, Abbeyleix Bog Project, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015
      </td>
      <td>
        Collaborative Community Project, Heritage House Museum, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015
      </td>
      <td>
        Life Drawing – Eamon Colman, Dunamaise Arts Centre, Portlaoise, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2014
      </td>
      <td>
        Jewellery and Silversmithing Skills Course, The School of Jewellery, North Circular Road, Dublin 7.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2014
      </td>
      <td>
      Ceramic Wheel Throwing – Karen Morgan, Grennan Mill Craft School, Thomastown, Co. Kilkenny.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        1997
      </td>
      <td>
        Certificate in Interior Design, B.A.S.E. Swords, Co. Dublin.
      </td>
    </tr>
    
    <br/><br/>
    SOLO EXHIBITION
    <br/><br/>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "Unfurled", Solo Exhibition, Abbeyleix Library Gallery, Abbeyleix, Co. Laois. 
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "Moving On", Solo Exhibition, Laois Arthouse Gallery, Abbeyleix, Co. Laois. 
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "Unfurling", Solo Exhibition, Birr Theatre and Arts Centre, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2018
      </td>
      <td>
        “A Fine Line”, Solo Exhibition, Abbeyleix Library Gallery, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
      2017-2018
      </td>
      <td>
        “Fragile Strength”, Solo Exhibition, Abbeyleix Library Gallery, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
      2016
      </td>
      <td>
        “Fragile Strength”, Solo Exhibition, Abbeyleix Library Gallery, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <br/><br/>
    SELECTED GROUP EXHIBITIONS
    <br/><br/>
    {/* 
    */}
    <tr>
      <td className="cv-year">
        2023
      </td>
      <td>
        ‘Between the Lines’, Cahir Arts Gallery, Cahir, Co. Tipperary.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2023
      </td>
      <td>
        ‘Commuvere’, Birr Vintage Week Open Submission, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2023
      </td>
      <td>
        ‘Clay/Works’, The Printworks, Dublin Castle, Dublin 2.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022-2023
      </td>
      <td>
        ‘Common Ground “Dark/Light”’, Birr Theatre &amp; Arts Centre, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        ‘IT’S BOLD’, Crescent Shopping Centre, Dooradoyle, Limerick.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        ‘Between the Sights of the Sun’, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        Birr Vintage Week Open Submission, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        ‘Clay/Works’, The Printworks, Dublin Castle, Dublin 2.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        ‘Ceramics #2’, Cahir Arts Gallery, Cahir, Co. Tipperary.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "LSAD Artists on Display", Crescent Shopping Centre, Dooradoyle, Limerick.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021-2022
      </td>
      <td>
        "Common Ground `On the Farm`", Birr Theatre & Arts Centre, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021-2022
      </td>
      <td>
        "In Trust. In Gratitude. In Hope", Laois Arthouse Gallery, Stradbally, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021-2022
      </td>
      <td>
        Open Submission, Dunamaise Arts Centre, Portlaoise, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "Laois Arthouse 10<sup>th</sup> Anniversary Exhibition", Laois Arthouse Gallery, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "National Comfort Blanket", Cowshed Gallery, Farmleigh, Dublin 15. 
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "Clay/Works", The Printworks, Dublin Castle, Dublin 2. 
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "Collective Thoughts", Dunamaise Arts Centre and Theatre, Portlaoise, Co. Laois. 
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        "A Year of Magical Thinking", Dunamaise Arts Centre and Theatre, Portlaoise, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020-2021
      </td>
      <td>
        Common Ground “Minimal”, Birr Theatre & Arts Centre, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        “The Collective”, Laois Arthouse, Stradbally, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        “Stories from Lismore and Beyond”, Lismore Castle Arts, Lismore, Co. Waterford.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        “Clay/Works”, The Printworks, Dublin Castle, Dublin 2.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        “Narrating the New Normal”, Open Submission, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        “Answers on a Postcard”, Nääs allé 3, 448 92 Floda, Sweden
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        “Answers on a Postcard”, Limerick School of Art and Design,<br/>
        Limerick and the Reykjavik School of Visual Arts, Iceland.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        Ceramics Ireland Selected Exhibition, Rathfarnham Castle Arts,<br/>
        Rathfarnham Castle, Dublin 14.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        Open Submission, Birr Vintage Week, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        “Clay/Works”, The Printworks, Dublin Castle, Dublin 2.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        LSAD Graduate Show, Clare Street, Limerick.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2018
      </td>
      <td>
        “Clayworks”, Limerick City Library, Michael Street, Limerick.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2017
      </td>
      <td>
        Open Submission, Birr Vintage Week, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2016
      </td>
      <td>
        Graduation Exhibition. Dunamaise Arts Centre, Portlaoise, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2016
      </td>
      <td>
        Open Submission, Birr Vintage Week, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015-2016
      </td>
      <td>
        Common Ground “A Touch of Red”, Birr Theatre & Arts Centre, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015
      </td>
      <td>
        “En Plein Air”, Laois Arthouse, Stradbally, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2015
      </td>
      <td>
        Graduation Exhibition. Dunamaise Arts Centre, Portlaoise, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2014
      </td>
      <td>
        “Celebrating Art in Nature”, The Source Arts Centre, Thurles, Co. Tipperary.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2008
      </td>
      <td>
        Open Submission, Birr Vintage Week, Birr, Co. Offaly.
      </td>
    </tr>

    <br/><br/>
      CURATION
    <br/><br/>

    <tr>
      <td className="cv-year">
        2020&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        Co-curated “The Collective”, Laois Arthouse, Stradbally, Co. Laois.
      </td>
    </tr>

    <br/><br/>
      COMMUNITY AND EDUCATIONAL COLLABORATION
    <br/><br/>

    <tr>
      <td className="cv-year">
        2023&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        Summer Arts Programme, Portarlington, Co Laois
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2023
      </td>
      <td>
        Tobar An Léinn National School, Mountrath, Co. Laois
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2023
      </td>
      <td>
        St Brendan’s Primary School, Birr, Co. Offaly.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2023
      </td>
      <td>
        St Cronan’s National School, Rosemount, Roscrea, Co. Tipperary.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        Summer Arts Programme, Rathdowney, Co Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        Summer Arts Programme, Clonaslee, Co Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        Summer Arts Programme, Mountmellick, Co Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2022
      </td>
      <td>
        St Colmcille’s National School, Errill, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        Tobar An Léinn National School, Mountrath, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        Scoil Fionntáin Naofa Shanahoe, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        re:CENTRING Laois, Laois Arthouse Gallery, Stradbally, Co. Laois.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021
      </td>
      <td>
        Culture Night, Laois Arthouse Gallery, Stradbally, Co. Laois. 
      </td>
    </tr>


    <br/><br/>
      AWARDS/COLLECTIONS/COMMISSIONS
    <br/><br/>

    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        Highly Commended, Visual Arts Category, Global Undergraduate Awards.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019-2020
      </td>
      <td>
        Ingenium Training and Consultancy Collection, Limerick
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        LIT Sports Award for Excellence shown during the BA (Hons)<br/>
        Degree in Design (Ceramics).
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        LIT Permanent Collection Purchase Award.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        BD Research Centre Ireland Purchase Award.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        Limerick School of Art and Design Permanent Collection.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2016
      </td>
      <td>
        Student of the Year, Abbeyleix FEC.
      </td>
    </tr>

    <br/><br/>
      PUBLICATIONS, VIDEO and ONLINE
    <br/><br/>

    <tr>
      <td className="cv-year">
        2021&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
      "In Trust. In Gratitude. In Hope.", Monica Flynn &amp; Muireann Ní Chonaill, Impress Printing Works, Dublin, ISBN: 978—9931333-4-3.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2021&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        “Irish Ceramics” Third Edition publication, John Goode, Colourprint Kerry, ISBN-13: 978-0956798732.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td>
        “Transformation of a Georgian Building in Limerick” video includes commissioned artwork.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        “Narrating the New Normal” Artists discussion with Aideen Barry, participant in Artist panel.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2020
      </td>
      <td>
        “The 68 Project” publication, Ingenium TC.
      </td>
    </tr>
    <tr>
      <td className="cv-year">
        2019
      </td>
      <td>
        “New Generation Gallery”, Irish Arts Review.
      </td>
    </tr>

    <br/><br/>
      PROFESSIONAL MEMBERSHIPS
    <br/><br/>

    <tr>
      <td>
        Visual Artists Ireland
      </td>
    </tr>
    <tr>
      <td>
        Ceramics Ireland
      </td>
    </tr>
    <tr>
      <td>
        Design & Crafts Council Ireland
      </td>
    </tr>
    <tr>
      <td>
        Irish Ceramics
      </td>
    </tr>

    <br/><br/>
      WORK STOCKED
    <br/><br/>

    <tr>
      <td>
        Visitor Centre, Copper Coast UNESCO Global Geopark, Bunmahon, Co. Waterford.
      </td>
    </tr>
    <tr>
      <td>
        Anthology, Unit 2, Market Square, Abbeyleix, Co. Laois.
      </td>
    </tr>
    <tr>
      <td>
        Cahir Arts, 26 Castle Street, Cahir, Co. Tipperary.
      </td>
    </tr>
    <tr>
      <td>
        Solas Eco Garden Centre, Dublin Road, Portarlington, Co. Laois.
      </td>
    </tr>
  </p>
  </TextBlock>
);

export default CV;