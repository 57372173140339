import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';

ReactDOM.render(
  <div>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </div>,
  document.querySelector('#root')
);

serviceWorker.unregister();
