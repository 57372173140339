import React from 'react';
import './Base.css';

function Base(props) {
  return (
    <div className="App">
      {props.children}
    </div>
  );
}

export default Base;
