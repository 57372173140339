import React, { Component } from 'react';
import './MenuItem.css';
import SubMenuItem from '../SubMenuItem/SubMenuItem';

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false }
  }

  toggleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open
    }));
  }

  render() {
    return (
      <React.Fragment>
        {this.props.subNavs ? (
        <div
          className="caret"
          onClick={this.toggleOpen}
        />
        ) : null}
        <div 
          className={`menu-item ${this.props.active ? 'active' : ''}`}
          onClick={this.props.onClick}
        >
          <span>
            {this.props.itemName.toUpperCase()}
          </span>
        {this.props.subNavs ? 
            <ul 
              className={`sub-nav ${this.state.open ? 'sub-nav-open' : ''}`}
              onClick={e => e.stopPropagation()}
            >
              {this.props.subNavs.map((item, key) => (
                <SubMenuItem item={item} key={key} />
              ))}
            </ul>
          : null}
        </div>
      </React.Fragment>
    );
  }
}

export default MenuItem;
