import React from 'react';
import Menu from '../Menu/Menu';
import './Page.css'

function Page(props) {
  return (
    <div className="page">
      <Menu />
      <div className="page-content">
        {props.children}
      </div>
    </div>
  );
}

export default Page;
