import React, { Component } from 'react';
import './Tile.css';

class Tile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mouseOver: false
    };
  }
 
  mouseEnter = (e) => {
    e.preventDefault();
    if (this.state.mouseOver === false) {
      this.setState({
        mouseOver: true
      });
    }
  }

  mouseLeave = (e) => {
    e.preventDefault();
    if (this.state.mouseOver === true) {
      this.setState({
        mouseOver: false
      });
    }
  }

  clickHandler = (e) => {
    e.preventDefault();
    this.setState(prevState => ({open: !prevState.open}));
  }

  render() {

    return (
      <React.Fragment>
        <div className="tile">
          <img
            onMouseEnter={this.mouseEnter}
            onMouseLeave={this.mouseLeave}
            onClick={this.clickHandler}
            src={this.props.image}
            alt={this.props.title}
          />
          <div class='tile-overlay'>
            <div class='tile-title'>
            {this.props.title}
            </div>
            <div class='tile-dimensions'>
            {this.props.dimensions}
            </div>
          </div>
        </div>
        {
          this.state.open ? 
          <div 
            className="expanded-image-wrapper"
            onClick={this.clickHandler}
          >
            <img
              className="large-image"
              src={this.props.image}
              alt={this.props.title}
            />
          </div>
          : null
        }
      </React.Fragment>
    );
  }
}

export default Tile;