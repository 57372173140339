import React from 'react';
import './CloseHamburger.css';

const CloseHamburger = (props) => (
  <div
    className="close-hamburger-wrapper"
    onClick={props.onClick}
  >
    <label className="close-hamburger">
      <span/>
    </label>
  </div>
);

export default CloseHamburger;
