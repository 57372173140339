import React from 'react';
import './Banner.css';

const Banner = (props) => (
  <div className="banner" style={{backgroundImage: `url("${props.img}")`}}>
    <div className="banner-content">
      <div className="banner-title">
        {props.title}
      </div>
      <div className="banner-text">
        {props.text}
      </div>
      <div className="banner-author">
        {props.author}
      </div>
      <a className="banner-email" href={`mailto:${props.email}`}>
        {props.email}
      </a>
    </div>
  </div>
);

export default Banner;
